<template>
  <div>
    <div class="type_list">
      <div class="clearfix posi">
        <span class="list_span">{{ typeName.type1 }}:</span>
        <ul
          class="type-content typelist clearfix"
          :class="{ typelisthei: fixedcheck == true }"
        >
          <li
            v-for="(item, index) in typeArr"
            :key="index"
            :class="{ active: item.type_id == resActive }"
            @click="restypechange(item.type_id)"
          >
            {{ item.type_name }}
          </li>
        </ul>
      </div>
      <el-divider></el-divider>
      <div class="clearfix">
        <span class="list_span">{{ typeName.type2 }}:</span>
        <ul class="type-content">
          <li
            v-for="item in typeformatypearr"
            :key="item.fmttype_id"
            :class="{ active: item.fmttype_id == fmtActive }"
            @click="fmttype(item.fmttype_id)"
          >
            {{ item.fmttype_name }}
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class="type_list" v-else>
      <div class="clearfix posi">
        <span class="list_span">{{ typeName.type1 }}:</span>
        <ul
          class="type-content typelist clearfix"
          :class="{ typelisthei: fixedcheck == true }"
        >
          <li
            v-for="(item, index) in list1"
            :key="index"
            :class="{ active: index == resActive }"
            @click="hh(index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <el-divider></el-divider>
      <div class="clearfix">
        <span class="list_span">{{ typeName.type2 }}:</span>
        <ul class="type-content">
          <li
            v-for="(item, index) in list2"
            :key="index"
            :class="{ active: index == fmtActive }"
            @click="hh1(index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>
<script>
import eventBus from "@/api/eventBus.js";
export default {
  props: ["typeArr", "typeformatypearr", "typeName", "hnum"],
  name: "",
  data() {
    return {
      resActive: 0,
      fmtActive: 0,
      item: "",
      fixedcheck: false,
      resActivea: "",
      gong: false,
      list1: [{ name: "全部" }, { name: "协同备课" }, { name: "共享课件" }],
      list2: [
        { name: "全部" },
        { name: "本日" },
        { name: "本周" },
        { name: "本月" },
        { name: "本学期" },
      ],
    };
  },
  watch: {
    resActivea(val) {
      this.$emit("resActive", val);
    },
    fmtActive(val) {
      this.$emit("fmtActive", val);
    },
  },
  mounted() {
    if(this.$route.query.id==1){
      this.resActive = 1;
    }
    eventBus.$on("gong", (res) => {
      if (res == 1) {
        this.gong = true;
      } else {
        this.gong = false;
      }
    });
  },
  methods: {
    hh(el) {
      // console.log(el);
      this.resActive = el;
    },
    hh1(el) {
      // console.log(el);
      this.fmtActive = el;
    },
    restypechange(e) {
      this.resActive = e;
      this.resActivea = e;
      this.fixedcheck = false;
    },
    fmttype(e) {
      this.fmtActive = e;
    },
    morebtn(e) {
      if (this.fixedcheck == true) {
        this.fixedcheck = false;
      } else {
        this.fixedcheck = true;
      }
    },
  },
};
</script>

<style  lang="less" scoped>
.el-divider--horizontal {
  margin: 0;
}
</style>
<style  lang="less" scoped>
.type_list {
  width: 958px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
}
.type-content {
  display: flex;
  justify-content: safe;
}
.type-content li,
.list_span {
  float: left;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  /* letter-spacing: 0px; */
  color: #2f2f2f;
  /* width: 68px; */
  padding: 0 5px;
  line-height: 28px;
  border-radius: 5px;
  margin-top: 18px;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.type-content li:hover {
  background-color: #3688fc;
  color: #ffffff;
}
.type-content .active {
  background-color: #3688fc;
  color: #ffffff;
}
</style>
