<template>
  <div>
    <div class="sort_content">
      <ul class="clearfix fl">
        <li :class="{ sort_active: shows == 'up' }" @click="scShow()">
          {{ sortJson.sort1 }}
        </li>
        <li :class="{ sort_active: shows == 'down' }" @click="xzShow()">
          {{ sortJson.sort2 }}
        </li>
        <!-- 评分最高 -->
        <!-- <li :class="{ sort_active: shows == 'score' }" @click="pfShow()">
          {{ sortJson.sort3 }}
        </li> -->
      </ul>
      <div class="ziyuan fr">
        <span class="zy_span"
          >共<span class="zynumber">{{ Number(listconhttpnum) }}</span
          >条资源</span
        >
        <div class="sousuo">
          <el-input
            v-model="input"
            placeholder="请输入关键字"
            @change="subchange(input)"
            background-color="red"
          >
            <div slot="suffix" class="top_icon">
              <i class="el-input__icon el-icon-search"></i>
            </div>
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/api/eventBus.js";
export default {
  props: ["sortJson", "listconhttpnum"],
  name: "",
  data() {
    return {
      shows: "down",
      input: "",
    };
  },
  mounted() {
  },
  methods: {
    subchange(val) {
      this.$emit("subinput", val);
    },
    scShow() {
      this.shows = "up";
      this.$emit("orderkey", this.shows);
    },
    xzShow() {
      this.shows = "down";
      this.$emit("orderkey", this.shows);
    },
    pfShow() {
      this.shows = "score";
      this.$emit("orderkey", this.shows);
    },
  },
};
</script>
<style  lang="less" scoped>
.sort_content {
  width: 680px;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(33, 22, 19, 0.07);
  border-radius: 4px;
  ul {
    li {
      float: left;
      width: 116px;
      text-align: center;
      line-height: 50px;
      border-radius: 4px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #5e6062;
      cursor: pointer;
    }
  }
  .sort_active {
    background-color: #3b8bfd;
    color: #fff;
    border-radius: 4px;
  }
  .ziyuan {
    margin-top: 5px;
    margin-right: 7px;
    .zy_span {
      margin-right: 7px;
    }
    .zynumber {
      color: #3888fc;
    }
    .sousuo {
      display: inline-block;
    }
    .el-input {
      width: 100%;
    }
  }
}
</style>
