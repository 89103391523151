<template>
  <div class="listContent">
    <div class="zanwuicon" v-if="num == 0">
      <svg class="icon left" aria-hidden="true">
        <use xlink:href="#iconkejian"></use>
      </svg>
      <span>暂无资源</span>
    </div>
    <ul class="st_list">
      <li class="list_item" v-for="(item, index) in arr" :key="index">
        <div class="item_top clearfix" @click="item_xiangqing(item, index)">
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'ppt'"
          >
            <use xlink:href="#iconppt"></use>
          </svg>
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'ptx'"
          >
            <use xlink:href="#iconppt"></use>
          </svg>
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'doc'"
          >
            <use xlink:href="#iconword"></use>
          </svg>
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'ocx'"
          >
            <use xlink:href="#iconword"></use>
          </svg>
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'lsx'"
          >
            <use xlink:href="#iconexcel"></use>
          </svg>
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'png'"
          >
            <use xlink:href="#icontupian1"></use>
          </svg>
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'jpg'"
          >
            <use xlink:href="#icontupian1"></use>
          </svg>
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'exe'"
          >
            <use xlink:href="#iconweizhiwenjian"></use>
          </svg>
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'mp4'"
          >
            <use xlink:href="#iconshipin1"></use>
          </svg>
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'mp3'"
          >
            <use xlink:href="#iconshipin1"></use>
          </svg>
          <svg
            class="icon fl"
            aria-hidden="true"
            v-if="item.file_name.substring(item.file_name.length - 3) == 'wmv'"
          >
            <use xlink:href="#iconweizhiwenjian"></use>
          </svg>
          <ul class="item_content right">
            <li class="lc_title">{{ item.file_name }}</li>
            <li class="lc_jianjie">简介：&nbsp;{{ item.file_name }}</li>
            <li class="lc_time">
              <ul class="fabu_content">
                <li>发布时间：{{ item.create_time }}</li>
                <li v-if="(item.file_size / 1024).toFixed(0) < 1024">
                  文件大小：{{ (item.file_size / 1024).toFixed(0) }} KB
                </li>
                <li v-else>
                  文件大小：{{ (item.file_size / 1024 / 1024).toFixed(0) }}MB
                </li>
                <li>下载：{{ item.downnum }} 次</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="item_upload">
          <div class="item_icon">
            <svg class="icon fl" aria-hidden="true">
              <use xlink:href="#iconduomeitiicon-"></use>
            </svg>
            <span class="upload_user">上传者：{{ item.upload_uname }}</span>
          </div>
          <!-- <ul class="fr icon">
            <li>
              <img @click="dianzan()" src="@/assets/4.png" alt="" />
            </li>
            <li>
              <img @click="xinxi()" src="@/assets/2.png" alt="" />
            </li>
            <li>
              <img @click="xiazai()" src="@/assets/3.png" alt="" />
            </li>
            <li>
              <img @click="fasong(1)" v-if="iscollect" src="@/assets/collectno.png" alt="" />
              <img @click="fasong(2)" v-else src="@/assets/collect.png" alt="" />
            </li>
            <li>
              <img @click="$router.push('/prepareLessons/collaborativeLesson')" src="@/assets/5.png" alt="" />
            </li>
          </ul> -->
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import eventBus from "@/api/eventBus";
import URL from "@/api/index.js";
export default {
  props: [
    "fmtactive",
    "resactive",
    "chapter_id",
    "pagenum",
    "orderkeydata",
    "word",
    "type",
  ],
  name: "",
  cptid: "",
  data() {
    return {
      token: "",
      iscollect: false,
      arr: [],
      num: "",
      ff: "827KB",
      hh: "554",
      ii: "768",
      ll: 4.5,
      gg: "435",
      sctype: "",
      httpArr: {
        restype: "",
        format: "",
        cptid: "4tns8Km1",
        bookid: "",
        type: "",
        word: "",
        orderkey: "down",
        page: "",
        pagenum: "8",
      },
    };
  },
  watch: {
    fmtactive(val) {
      this.httpArr.page = 1;
      eventBus.$emit("page", 1);
      this.httpArr.format = val;
      this.listconhttp(this.httpArr);
    },
    resactive(val) {
      this.httpArr.page = 1;
      eventBus.$emit("page", 1);
      this.httpArr.restype = val;
      this.listconhttp(this.httpArr);
    },
    chapter_id(val) {
      this.httpArr.page = 1;
      eventBus.$emit("page", 1);
      this.httpArr.cptid = val;
      this.listconhttp(this.httpArr);
    },
    pagenum(val) {
      this.httpArr.page = val;
      this.listconhttp(this.httpArr);
    },
    orderkeydata(val, old) {
      if (val != old) {
        this.httpArr.orderkey = val;
        this.httpArr.page = 1;
        eventBus.$emit("page", 1);
        this.listconhttp(this.httpArr);
      }
    },
    word(val) {
      this.httpArr.page = 1;
      eventBus.$emit("page", 1);
      this.httpArr.word = val;
      this.listconhttp(this.httpArr);
    },
    type(val) {
      this.httpArr.page = 1;
      eventBus.$emit("page", 1);
      this.httpArr.type = val;
      this.listconhttp(this.httpArr);
    },
  },
  mounted() {
    eventBus.$on("my", (res) => {
      if (res) {
        this.listconhttp(this.httpArr);
      }
    });
    this.listconhttp(this.httpArr);
  },
  methods: {
    item_xiangqing(item, index) {
      // console.log(item,index);
      var data = {
        fileid: this.arr[index].file_id,
      };
      this.$emit("book", item);
      this.$get(URL.fileView, data).then((res) => {});
      this.$router.push({
        path: "/resource/details",
        query: {
          data: this.arr[index],
        },
      });
      localStorage.setItem("item_xiangqing", JSON.stringify(this.arr[index]));
    },
    listconhttp(e) {
      // console.log(e);
      var that = this;
      var data = {
        cptid: e.cptid,
        orderkey: e.orderkey,
        format: e.format,
        bookid: e.bookid,
        restype: e.restype,
        type: e.type,
        word: e.word,
        page: e.page,
        pagenum: e.pagenum,
      };
      // 我的资源 token
      if (e.type == 3) {
        if (localStorage.getItem("loginInfo")) {
          this.token = JSON.parse(localStorage.getItem("loginInfo")).token;
        }
        var data = {
          cptid: e.cptid,
          orderkey: e.orderkey,
          format: e.format,
          bookid: e.bookid,
          restype: e.restype,
          type: e.type,
          word: e.word,
          page: e.page,
          pagenum: e.pagenum,
          token: this.token,
        };
      }

      this.$post(URL.selresource, data).then((res) => {
        // if (
        //   !localStorage.getItem("loginInfo") &&
        //   res.result.list[0].type == "3"
        // ) {
        //   that.arr = [];
        //   that.num = 0;
        //   return false;
        // }
        // console.log(res.result.list);
        that.arr = res.result.list;
        for (var i = 0; i < that.arr.length; i++) {
          that.arr[i].create_time = that.arr[i].create_time.slice(0, 10);
        }
        that.num = res.result.pagedata.listsum;
        this.$emit("listconhttp", that.num);
      });
    },
    dianzan() {
      // console.log(11);
    },
    xiazai() {
      //console.log(2);
    },
    xinxi() {
      // console.log(3);
    },
    fasong(res) {
      if (res == 1) {
        this.iscollect = false;
      } else {
        this.iscollect = true;
      }
      // console.log(4);
    },
  },
};
</script>

<style scoped lang="less">
.zanwuicon {
  width: 300px;
  margin: 50px auto;
}
.zanwuicon span {
  text-align: center;
  display: block;
  color: #ccc;
}
</style>


<style scoped lang="less">
.st_list .list_item {
  width: 680px;
  height: 187px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.st_list {
  margin-top: 10px;
}
.list_item {
  border-top: 0.1px solid #fff;
  margin-bottom: 10px;
}
.iconpdf {
  width: 55px;
  height: 55px;
}
.iconPDF {
  font-size: 40px;
}
.lc_title,
.lc_jianjie,
.lc_time {
  font-family: MicrosoftYaHei;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #2f2f2f;
  line-height: 39px;
}
.lc_title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 552px;
}
.lc_jianjie {
  font-size: 14px;
  letter-spacing: 0px;
  color: #6e7578;
  line-height: 34px;
}
.lc_time {
  font-size: 12px;
  letter-spacing: 0px;
  color: #a7a8a8;
  line-height: 37px;
}

.item_top {
  margin: 13px 17px 0px 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;
}
.fabu_content {
  overflow: hidden;
}
.fabu_content li {
  float: left;
  margin-right: 40px;
}
.item_upload {
  height: 53px;
  padding: 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload_user {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #a7a8a8;
}
.item_top .icon {
  width: 93px;
  height: 104px;
}
.item_icon {
  display: flex;
  align-items: center;
}
.item_icon .icon {
  width: 18px;
  height: 18px;
  margin-right: 7px;
}
.icon {
  li {
    float: left;
    margin-right: 20px;
    cursor: pointer;
    img {
      width: 20px;
    }
  }
}
</style>
