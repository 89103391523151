<template>
  <div>
    <!-- <top></top> -->
    <!-- <navMenu></navMenu> -->
    <div class="inner">
      <materSelect @chapter="chapter"></materSelect>
      <div class="clearfix">
        <resourList
          @chapterid="chapterid"
          :chapterlist="chapterlist"
          class="fl"
        ></resourList>
        <div class="clearfix fr">
          <!-- 三种资源类型 -->
          <!-- <resourceType @repository="repository"></resourceType> -->
          <typeList
            @resActive="resActive"
            @fmtActive="fmtActive"
            :typeArr="arr"
            :typeformatypearr="formatypearr"
            :typeName="type_name"
            :hnum="num"
            class="fr"
          ></typeList>
          <div class="clearfix">
            <div class="sort_list fl">
              <sort
                @subinput="subinput"
                @orderkey="orderkey"
                :listconhttpnum="listconhttpnum"
                :sortJson="sort_name"
              ></sort>
              <listContent
                @book="book"
                :word="word"
                :type="type"
                :orderkeydata="orderkeydata"
                :fmtactive="fmtactive"
                :resactive="resactive"
                :pagenum="pagenum"
                :chapter_id="chapter_id"
                @listconhttp="listconhttp"
              ></listContent>
              <pagination
                @pagenew="pagenew"
                :listconhttpnum="listconhttpnum"
              ></pagination>
            </div>
            <recommendation class="jpin-tuijian fr"></recommendation>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import top from "@/components/common/top.vue";
// import navMenu from "@/components/common/navMenu.vue";
import URL from "@/api/index.js";
import materSelect from "@/components/resource/masterSelect";
import resourList from "@/components/resource/resourList";
import typeList from "@/components/resource/typeList";
import sort from "@/components/resource/sort";
import listContent from "@/components/resource/listContent";
import resourceType from "@/components/resource/resourceType";
import pagination from "@/components/resource/pagination";
import recommendation from "@/components/resource/recommendation";
export default {
  data() {
    return {
      title_num: "1",
      type_name: { type1: "类型", type2: "格式" },
      sort_name: { sort1: "最新上传", sort2: "最多下载", sort3: "评分最高" },
      arr: [],
      formatypearr: [],
      num: {
        one: 117,
        two: 60,
        three: 35,
      },
      datal: {
        book_name: "",
        chapter_name: " ",
        file_name: "",
        file_type: "",
        file_fmt: "",
      },
      chapterlist: [],
      listconhttpnum: "",
      chapter_id: "",
      resactive: "",
      fmtactive: "",
      pagenum: "",
      orderkeydata: "",
      word: "",
      // 库 type
      type:"",
    };
  },
  components: {
    // top,
    resourceType,
    // navMenu,
    materSelect,
    resourList,
    typeList,
    sort,
    listContent,
    pagination,
    recommendation,
  },
  created(){
    // console.log(JSON.parse(localStorage.getItem("id")))
    // if(!JSON.parse(localStorage.getItem("id"))){
    //   localStorage.setItem("id", JSON.stringify(1));
    // }
    if(!JSON.parse(localStorage.getItem("sid"))){
      localStorage.setItem("sid", JSON.stringify(1));
    }
    if(!JSON.parse(localStorage.getItem("book_id"))){
      localStorage.setItem("book_id", JSON.stringify({ id: "1" }))
    }
  },
  mounted() {
    this.resourhttp();
    this.getformatype();
  },
  methods: {
    convertKey(arr, key) {
      let newArr = [];
      arr.forEach((item, index) => {
        let newObj = {};
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]];
        }
        newArr.push(newObj);
      });
      return newArr;
    },
    //类型
    resourhttp() {
      var that = this;
      this.$post(URL.getresoursetype).then((res) => {
        // console.log(res);
        var resour_data = res.result.type_list;
        that.arr = resour_data;
        that.arr = that.convertKey(that.arr, ["type_id", "type_name"]);
      });
    },
    // 库
    repository(val){
      // console.log(val);
      this.type = val.type
    },
    //格式
    getformatype() {
      this.$post(URL.getformatype).then((res) => {
        // console.log(res);
        this.formatypearr = res.result.type_list;
      });
    },
    //课本chapter
    chapter(val) {
      // console.log(val);
      this.chapter_id = val[0].chapter_id;
      this.chapterlist = val;
    },
    //中间数据长度
    listconhttp(val) {
      // console.log(val);
      this.listconhttpnum = val;
    },
    // 格式
    resActive(val) {
      // console.log(val);
      this.resactive = val;
    },
    // 类型
    fmtActive(val) {
      this.fmtactive = val;
      // console.log(val);
    },
    chapterid(val) {
      // console.log(val);
      this.chapter_id = val;
    },
    //页码
    pagenew(val) {
      // console.log(val);
      this.pagenum = val;
    },
    //上传、下载、评分
    orderkey(val) {
      // console.log(val);
      this.orderkeydata = val;
    },
    //搜索框
    subinput(val) {
      this.word = val;
    },
    book(val) {
      var that = this;
      var shuzu = [];
      shuzu.push(val);
      shuzu.forEach(function (data) {
        that.arr.forEach(function (res1) {
          if (data.restype_id == res1.type_id) {
            that.datal.file_type = res1.type_name;
          }
        });
        that.formatypearr.forEach(function (res2) {
          if (data.fmttype_id == res2.fmttype_id) {
            that.datal.file_fmt = res2.fmttype_name;
          }
        });
        that.datal.book_name = data.book_name;
        that.datal.file_name = data.file_name;
        window.localStorage.setItem("file_type", that.datal.file_type);
        window.localStorage.setItem("file_fmt", that.datal.file_fmt);
      });
    },
    
  },
};
</script>

<style lang='less'   scoped>
.sort_list,
.jpin-tuijian {
  margin-top: 16px;
}
</style>