<template>
  <div class="">
    <div class="resour_list">
      <el-tree
        icon-class="el-icon-circle-plus"
        :data="chapterlist"
        empty-text="暂无资源"
        :props="defaultProps"
        highlight-current
        node-key="chapter_id"
        :default-expanded-keys="treeExpandData"
        accordion
        @node-click="handleNodeClick"
        :expand-on-click-node="false"
      >
      </el-tree>
    </div>
  </div>
</template>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
<script>
import eventBus from "@/api/eventBus";
export default {
  props: ["chapterlist"],
  name: "",
  data() {
    return {
      treeData: "",
      treeExpandData: [],
      cptid: "",
      defaultProps: {
        children: "son",
        label: "chapter_name",
      },
    };
  },
  mounted() {},
  watch: {
    chapterlist(val, old) {
      if (val != old) {
        this.treeExpandData.push(val[0].chapter_id);
      }
    },
  },
  methods: {
    handleNodeClick(e) {
      // console.log(e);
      e.chapter_name;
      localStorage.setItem("data_zjname", JSON.stringify(e.chapter_name));
      eventBus.$emit("cptid", e.chapter_id);
      // VueEvent.$emit('cptid',e.chapter_id)
      this.$emit("chapterid", e.chapter_id);
      this.$emit("chaptername", e.chapter_name);
    },
  },
};
</script> 

<style  lang="less" scoped>
.resour_list {
  width: 228px;
  min-height: 590px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 15px;
  box-sizing: border-box;
}
/deep/ .el-tree-node__label {
  font-size: 14px;
  display: inline-block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-tree/deep/.el-tree-node__expand-icon {
  color: #2c84ff !important;
}
.el-tree/deep/.el-tree-node__expand-icon.is-leaf {
  color: transparent !important;
}
</style>
