<template>
  <div class="pagin">
    <el-pagination
      v-show="num"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="10"
      layout="prev, pager, next, jumper"
      :total="Number(listconhttpnum)"
    >
    </el-pagination>
  </div>
</template>

<script>
import eventBus from "@/api/eventBus";
export default {
  props: ["listconhttpnum"],
  name: "",
  data() {
    return {
      num: true,
      sum: null,
      currentPage: 1,
    };
  },
  watch: {
    listconhttpnum(val) {
      if (val == 0) {
        this.num = false;
      } else {
        this.num = true;
      }
    },
  },
  mounted() {
    eventBus.$on("page", (res) => {
      this.currentPage = res;
    });
  },
  methods: {
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      // console.log(val);
      this.$emit("pagenew", val);
    },
  },
};
</script>

<style lang="less" scoped>
.el-pager .number,
.el-pager .el-icon,
.el-pagination .btn-prev,
.el-pagination .btn-next {
  background-color: transparent;
}
.el-pager li:not(.disabled).active {
  height: 28px;
  border-radius: 50%;
  line-height: 28px;
  background-color: #3198fe;
  color: #fff;
}
</style>
<style scoped lang="less">
.pagin {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
}
</style>
