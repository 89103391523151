<template>
  <div class="box">
    <ul class="clearfix">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="{ active: index == resActive }"
        @click="handlechange1(item)"
      >
        {{ item.name }}
      </li>
      <!-- <li class="fr">
        <el-button type="danger" @click="handledialog">上传资源</el-button>
      </li> -->
    </ul>
    <!-- 本地上传 弹框 -->

    <el-dialog
      title="本地上传素材"
      :visible.sync="dialogFormVisible"
      width="30%"
      append-to-body
    >
      <el-form :model="form">
        <el-form-item label="素材类型：" style="margin-top: 20px">
          <el-select
            style="width: 20%"
            v-model="addfiledata.title_list_list"
            @change="choserestype"
          >
            <el-option
              v-for="item in title_list"
              :key="item.restype_id"
              :label="item.restype_name"
              :value="item.restype_id"
            >
            </el-option>
          </el-select>

          <span style="margin-left: 30px">素材格式：</span>

          <el-select
            style="width: 24%"
            v-model="addfiledata.formatypearr_list"
            @change="chosefmttype"
          >
            <el-option
              v-for="item in formatypearr"
              :key="item.fmttype_id"
              :label="item.fmttype_name"
              :value="item.fmttype_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择素材：" style="margin-top: 20px">
          <el-upload
            action="http://test.rujiaowang.net/api/resource/get_oss_key"
            :on-change="handleChange"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :before-upload="beforeUpload"
          >
            <el-button type="primary">本地上传</el-button>
            <span slot="tip" class="el-upload__tip">注意：单个文件上传 </span>
          </el-upload>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddfile">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import URL from "@/api/index.js";
import axios from "axios";
import $ from "jquery";
import eventBus from "@/api/eventBus.js";
export default {
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      fileList: [],
      title_list: "",
      formatypearr: "",
      addfiledata: {
        title_list_list: "",
        formatypearr_list: "",
        cptid: "",
        fileurl: "",
        token: "",
        name: "",
      },
      dialogFormVisible: false,
      resActive: 0,
      list: [
        { name: "云资源", id: 0, type: 1 },
        { name: "校本资源", id: 1, type: 2 },
        { name: "我的资源", id: 2, type: 3 },
      ],
    };
  },
  components: {},
  created() {},
  mounted() {
    localStorage.setItem("data_ziyuantype", JSON.stringify('云资源'));
    eventBus.$on("cptid", res => {
      this.addfiledata.cptid = res;
    });
    this.get_oss_key();
    this.getformatype();
    this.getresoursetype();
    if (this.$route.query.id == 1) {
      this.resActive = this.$route.query.id;
    }
  },
  watch: {
    resActive(val) {
      eventBus.$emit("gong", val);
    },
  },
  methods: {
    get_oss_key() {
      var that = this;
      axios
        .post("http://test.rujiaowang.net/api/resource/get_oss_key")
        .then((res) => {
          that.aliyunOssToken = res.data.result.osssign;
        });
    },
    // 本地上传 弹框
    handledialog() {
      this.fileList=[]
      if (!this.addfiledata.cptid) {
        this.$message({
          message: "请先选择具体的课程",
        });
      } else {
        this.dialogFormVisible = true;
      }
    },
    //上传本地资料 确定
    handleAddfile() {
      if (
        !this.addfiledata.title_list_list ||
        !this.addfiledata.formatypearr_list
      ) {
        this.$message({
          message: "请先选择素材格式和类型",
        });
        return false;
      }
      if (this.fileList.length == 0) {
        this.$message({
          message: "请选择素材文件",
        });
        return false;
      }
      this.dialogFormVisible = false;
      axios
        .post("http://test.rujiaowang.net/api/resource/addfile", {
          token: JSON.parse(localStorage.getItem("loginInfo")).token,
          name: this.fileact,
          fileurl: this.addfiledata.fileurl,
          fmttype: this.addfiledata.formatypearr_list,
          chapter_id: this.addfiledata.cptid,
          restype: this.addfiledata.title_list_list,
          type: "3",
        })
        .then((res) => {
          if (res.data.state == 0) {
            this.$message({
              type: "success",
              message: "上传成功",
            });
             eventBus.$emit("my", true);
            // this.handlechange({ id: 2, name: "我的资源", type: 3 });
          } else {
            this.$message({
              message: res.data.msg,
            });
          }
          this.$emit("upload", true);
          // VueEvent.$emit("upload", true);
          // localStorage.removeItem('cptid');
        });
    },
    beforeUpload(file) {
      var last = file.name.lastIndexOf(".");
      var postfix = file.name.substring(last);
      if (
        postfix != ".jpg" &&
        postfix != ".bmp" &&
        postfix != ".gif" &&
        postfix != ".jpeg" &&
        postfix != ".xls" &&
        postfix != ".xlsx" &&
        postfix != ".ppt" &&
        postfix != ".txt" &&
        postfix != ".mp4" &&
        postfix != ".doc" &&
        postfix != ".docx" &&
        postfix != ".pptx" &&
        postfix != ".mp3" &&
        postfix != ".wma" &&
        postfix != ".wav" &&
        postfix != ".wmv" &&
        postfix != ".asf" &&
        postfix != ".swf" &&
        postfix != ".rar" &&
        postfix != ".zip" &&
        postfix != ".m4a" &&
        postfix != ".flv" &&
        postfix != ".avi" &&
        postfix != ".png" &&
        postfix != ".mmap" &&
        postfix != ".xmind" &&
        postfix != ".ggb" &&
        postfix != ".pdf"
      ) {
        this.$message({
          message: "请上传正确的文件格式",
        });
        return false;
      }
      // 限制大小
      // const isLt500k = file.size / 1024 / 1024 < 0.48;
      // if (!isLt500k) {
      //   this.$message.error("上传图片大小不能超过 500k!");
      //   return false;
      // }
      var filename = file.name;
      var getSuffix = function (filename) {
        var pos = filename.lastIndexOf(".");
        var suffix = "";
        if (pos != -1) {
          suffix = filename.substring(pos); //文件后缀
        }
        return suffix;
      };

      filename =
        new Date().getTime() +
        Math.ceil(Math.random() * 10000) +
        getSuffix(filename);
      var _self = this;
      var formData = new FormData();
      //注意formData里append添加的键的大小写
      formData.append("key", _self.aliyunOssToken.dir + "eduyun/" + filename); //存储在oss的文件路径
      formData.append("OSSAccessKeyId", _self.aliyunOssToken.accessid); //accessKeyId
      formData.append("policy", _self.aliyunOssToken.policy); //policy
      formData.append("Signature", _self.aliyunOssToken.signature); //签名
      //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
      formData.append("callback", _self.aliyunOssToken.callback); //policy
      formData.append("file", file);
      formData.append("success_action_status", 200); //成功后返回的操作码
      var url = _self.aliyunOssToken.host;
      var fileUrl =
        _self.aliyunOssToken.host + "/" + _self.aliyunOssToken.dir + file.name;
      $.ajax({
        url: url,
        type: "POST",
        data: formData,
        // async: false,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          // _self.addimg(data)//添加图片接口
          // VueEvent.$emit("addimg", data);
          // console.log(data.filename);
          // _self.$nextTick(() => {
          _self.addfiledata.fileurl = data.filename;
          // this.dialogFormVisible = true;
          // });
          // console.log(this.dialogFormVisible);
        },
        error: function (data) {},
      });
      if (file != null) {
        this.$emit("upload", false);
      }
    },
    choserestype(e) {
      var that = this;
      that.addfiledata.title_list_list = e;
    },
    chosefmttype(e) {
      var that = this;
      that.addfiledata.formatypearr_list = e;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      this.fileact = file.name;
    },
    handlechange1(item) {
      localStorage.setItem("data_ziyuantype", JSON.stringify(item.name));
      this.$emit("repository", item);
      this.resActive = item.id;
    },
    //格式
    getformatype() {
      this.$post(URL.getformatype).then((res) => {
        this.formatypearr = res.result.type_list;
      });
    },
    // 类型
    getresoursetype() {
      var that = this;
      axios
        .post("http://test.rujiaowang.net/api/resource/getresoursetype")
        .then((res) => {
          this.title_list = res.data.result.type_list.slice(1, 20);
        });
    },
  },
};
</script>

<style lang='less' scoped>
.box {
  width: 958px;
  margin-bottom: 4px;
  background-color: #ffffff;
  height: 60px;
  line-height: 60px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  ul {
    li {
      display: inline-block;
      padding: 0 30px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .active {
    background-color: #3688fc;
    color: #ffffff;
    border-radius: 4px;
  }
}
</style>
