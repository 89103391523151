<template>
  <div class="select_box">
    <div class="mater_select inner">
      <div class="block">
        <div class="icon_left">
          <svg class="icon left" aria-hidden="true">
            <use xlink:href="#iconchazhaobiaodanliebiao"></use>
          </svg>

          <span class="demonstration">教材选择</span>
        </div>
        <div>
          <span>年级 :</span>
          <el-select v-model="nianji" @change="chosegrade">
            <el-option
              v-for="item in grade"
              :key="item.gid"
              :label="item.grade_name"
              :value="item.gid"
            >
            </el-option>
          </el-select>

          <span>科目 :</span>
          <el-select
            v-model="kemu"
            value-key="zjsidCopy"
            @change="chosesubject"
          >
            <el-option
              v-for="item in subject"
              :key="item.sid"
              :label="item.subject_name"
              :value="item"
            >
            </el-option>
          </el-select>

          <span>课本 :</span>
          <el-select
            v-model="keben"
            value-key="zjbook_id"
            @change="chosebooklist"
          >
            <el-option
              v-for="item in booklist"
              :key="item.book_id"
              :label="item.book_name"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import URL from "@/api/index.js";
export default {
  name: "",
  data() {
    return {
      grade: "",
      nianji: "一年级",
      gidnum: "",
      subject: "",
      kemu: "语文",
      booklist: [],
      keben: "",
      section_list: "",
    };
  },
  created() {
    // var id = JSON.parse(localStorage.getItem("id"));
    // if (id != null) {
    // } else {
    //   localStorage.setItem("id", JSON.stringify(1));
    // }
    var sid = JSON.parse(localStorage.getItem("zjsidCopy"));
    if (sid != null) {
    } else {
      localStorage.setItem("zjsidCopy", JSON.stringify(1));
    }

    var book_id = JSON.parse(localStorage.getItem("zjbook_id"));
    if (book_id != null) {
    } else {
      localStorage.setItem("zjbook_id", JSON.stringify({ id: "1" }));
    }

    this.nianji = JSON.parse(localStorage.getItem("zjgradeCopy"));

    if (this.nianji != null) {
      this.chosegrade1(this.nianji);
    } else {
      this.chosegrade1(1);
      this.nianji = "一年级";
    }
  },
  mounted() {
    localStorage.setItem("data_nianji", JSON.stringify("一年级"));
    this.gradehttp(); //初始化年级列表
  },
  watch: {
    // subject(value){
    //   if(value.length==0){
    //       this.keben=''
    //   }
    // },
  },
  methods: {
    // 年级切换
    chosegrade1(e) {
      // console.log(e);
      this.$emit("gid", e);
      localStorage.setItem("zjgradeCopy", JSON.stringify(e));
      this.subjecthttp(e); //获取科目列表
      if (JSON.parse(localStorage.getItem("zjsidCopy"))) {
        var texth = {
          gid: e,
          sid: JSON.parse(localStorage.getItem("zjsidCopy")),
        };
      } else {
        var texth = { gid: e, sid: "1" };
      }
      this.textbookhttp(texth); //获取课本列表n
    },
    // 年级切换
    chosegrade(e) {
      // console.log(e);
      this.grade.forEach((item) => {
        if (item.gid == e) {
          localStorage.setItem("data_nianji", JSON.stringify(item.grade_name));
        }
      });
      this.$emit("gid", e);
      localStorage.setItem("zjgradeCopy", JSON.stringify(e));
      localStorage.setItem("zjsidCopy", JSON.stringify(1));
      this.subjecthttp(e); //获取科目列表

      var texth = { gid: e, sid: "1" };
      this.textbookhttp(texth); //获取课本列表n
    },
    // 学科切换
    chosesubject(e) {
      // console.log(e);
      localStorage.setItem("data_kemu", JSON.stringify(e.subject_name));
      this.$emit("zjsidCopy", e.sid);
      this.kemu = e.subject_name;
      localStorage.setItem("zjsidCopy", JSON.stringify(e.sid));
      if (JSON.parse(localStorage.getItem("zjgradeCopy"))) {
        var texth = {
          gid: JSON.parse(localStorage.getItem("zjgradeCopy")),
          sid: e.sid,
        };
      } else {
        var texth = { gid: "1", sid: e.sid };
      }
      this.textbookhttp(texth); //获取课本列表
    },
    // 课本切换
    chosebooklist(e) {
      // console.log(e);
      this.keben = e.book_name;
      localStorage.setItem("data_bookid", JSON.stringify(e.book_id));
      localStorage.setItem("zjbook_id", JSON.stringify(e));
      if (JSON.parse(localStorage.getItem("zjbook_id"))) {
        this.chapter(JSON.parse(localStorage.getItem("zjbook_id")).book_id); //获取章节列表
      } else {
        this.chapter(e.book_id); //获取章节列表
      }
    },
    //获取所有年级列表
    gradehttp() {
      var that = this;
      this.$get(URL.allgrade).then((res) => {
        that.grade = res.result.grade_list;
        this.grade.forEach((item) => {
          if (item.gid == this.nianji) {
            localStorage.setItem(
              "data_nianji",
              JSON.stringify(item.grade_name)
            );
          }
        });
      });
    },
    //获取科目列表
    subjecthttp(e) {
      var num = JSON.parse(localStorage.getItem("zjsidCopy"));
      var that = this;
      var data = {
        gid: e,
      };
      this.$post(URL.getsubject, data).then((res) => {
        that.subject = res.result.subject_list;
        if (that.subject.length != 0) {
          that.subject.forEach((item) => {
            if (item.sid == num) {
              localStorage.setItem(
                "data_kemu",
                JSON.stringify(item.subject_name)
              );
              that.kemu = item.subject_name;
              this.$emit("zjsidCopy", item.sid);
            }
          });
          // that.kemu = that.subject[num - 1].subject_name;
        } else {
          that.kemu = "";
        }
        // this.$emit("zjsidCopy", that.subject[num - 1].sid);
      });
    },
    //获取课本列表
    textbookhttp(e) {
      // console.log(e);
      var book_id = JSON.parse(localStorage.getItem("zjbook_id")).id;
      var that = this;
      var data = {
        gid: e.gid,
        sid: e.sid,
      };
      this.$post(URL.getbooklist, data).then((res) => {
        if (e.sid == 1 || res.result.booklist[book_id - 1]) {
          localStorage.setItem(
            "data_bookid",
            JSON.stringify(res.result.booklist[book_id - 1].book_id)
          );
          this.chapter(res.result.booklist[book_id - 1].book_id);
        } else {
          // this.chapter(res.result.booklist[book_id - 1].book_id);
        }
        that.booklist = res.result.booklist;
        // 课本数据分类
        if (that.booklist.length != 0) {
          for (var i = 0; i < that.booklist.length; i++) {
            that.booklist[i].book_name = that.booklist[i].book_name; //课本名称拼接
          }
          that.keben = that.booklist[book_id - 1].book_name;
        } else {
          that.keben = "";
        }
      });
    },
    //获取章节列表
    chapter(e) {
      // console.log(e);
      var that = this;
      var data = {
        cptid: e,
      };
      this.$post(URL.bookchapter, data).then((res) => {
        // console.log(res);
        var data = res.result.menu;
        localStorage.setItem(
          "data_zjname",
          JSON.stringify(res.result.menu[0].chapter_name)
        );
        this.$emit("chapter", data);
      });
    },
  },
};
</script>
<style scoped lang="less">
.el-select {
  margin-left: 18px;
  margin-right: 34px;
}
</style>
<style scoped lang="less">
.select_box {
  
  margin-top: 24px;
  margin-bottom: 25px;
}
.mater_select {
  height: 64px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(33, 22, 19, 0.07);
  border-radius: 4px;
}
.block {
  display: flex;
  align-items: center;
  padding-top: 14px;
}
.demonstration {
  line-height: 18px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #3989fc;
  display: flex;
  justify-content: center;
}
.icon_left .icon {
  width: 22px;
  height: 22px;
  padding-top: 6px;
}
.icon_left {
  width: 103px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 22px;
  margin-right: 75px;
}
</style>
